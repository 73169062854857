import { useZodFormFieldObject } from '~/components/zod/ZodForm';
import React, { useEffect, useRef, useState } from 'react';
import { useTenant } from '~/tenants/common/TenantContextProvider';
import Uppy from '@uppy/core';

import '@uppy/core/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import { FileData } from '~/lib/schema/order';
import classNames from 'classnames';
import LoadingIcon from '~/components/icons/LoadingIcon';
import IconDelete from '~/components/icons/streamline/line/IconDelete';
import Button from '~/components/interactive/Button';

export default function ZodFieldFile({ name, prefix, template }: { name: string; prefix: string; template?: string }) {
  const [prefixed, file, setFile] = useZodFormFieldObject<Pick<FileData, 'name' | 'url' | 'mime' | 'bytes'>>(name);

  const tenant = useTenant();
  const [uppy, setUppy] = useState<Uppy | null>(null);
  const [uploading, setUploading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    import('~/components/upload/uppy_file.client').then((mod) => {
      if (!ref.current || uppy || ref.current.children.length > 0) {
        return;
      }

      const client = mod.default({
        tenant: tenant.id,
        prefix,
        template,
        target: ref.current,
        onUploading() {
          setUploading(true);
        },
        onComplete(file) {
          setFile({
            name: file.name,
            mime: file.mime,
            url: file.url,
            bytes: file.size,
          });
          setUploading(false);
        },
      });

      setUppy(client);
    });
  }, [ref.current]);

  return (
    <div className="pt-2">
      <div ref={ref} className={classNames('w-full h-48', { hidden: uploading || file })} />

      {uploading && !file && (
        <div className="w-full h-48 p-4 flex items-center justify-center">
          <div className="size-24">
            <LoadingIcon />
          </div>
        </div>
      )}
      {file ? (
        <>
          <div className="text-wrap flex items-center space-x-3">
            <Button
              icon={<IconDelete />}
              onClick={(e) => {
                e.preventDefault();
                setFile(null);
                setUploading(false);
              }}
              variant="transparent"
            />
            <div>{file.name}</div>
          </div>
          <input type="hidden" name={`${prefixed}.name`} value={file.name} />
          <input type="hidden" name={`${prefixed}.bytes`} value={file.bytes} />
          <input type="hidden" name={`${prefixed}.mime`} value={file.mime} />
          <input type="hidden" name={`${prefixed}.url`} value={file.url} />
        </>
      ) : (
        <>
          <input type="hidden" name={name} value={''} />
        </>
      )}
    </div>
  );
}
